@import 'var';

/***** Allgemein *****/

section {
    padding: 50px 0; }

.am-background {
    background-color: #eaf6f2; }

.am-divider {
    text-align: center;
    margin: 15px 0 50px 0;
    background: url('../images/line-03.png') no-repeat center top;
    height: 28px; }

.am-divider-left {
    text-align: left;
    margin: 15px 0 50px 0;
    background: url('../images/line-02.png') no-repeat left top;
    height: 28px; }

.am-black {
    background: url('../images/call-back-bg.jpg') repeat-y top center;
    background-size: cover;
    color: #ffffff; }

#back-to-top-button {
    display: inline-block;
    background-color: $am-green;
    width: 30px;
    height: 30px;
    text-align: center;
    border-radius: 4px;
    position: fixed;
    bottom: 30px;
    right: 30px;
    transition: background-color .3s, opacity .5s, visibility .5s;
    opacity: 0;
    visibility: hidden;
    z-index: 1000; }

#back-to-top-button::after {
    content: "\f077";
    font-family: 'Font Awesome 5 Free', serif;
    font-weight: 900;
    font-style: normal;
    font-size: 1.2rem;
    line-height: 30px;
    color: #fff; }

#back-to-top-button:hover {
    cursor: pointer;
    background-color: #333; }

#back-to-top-button:active {
    background-color: #555; }
#back-to-top-button.show {
    opacity: 1;
    visibility: visible; }

/***** HEADER *****/

header a.navbar-brand {
    background-color: rgba(0,0,0,0.5);
    padding: 1rem;
    margin-top: -1rem;

    img {
        max-width: 200px;

        @media (min-width: 640px) {
            max-width: 240px; } } }

header.subpage a.navbar-brand {
    background-color: transparent;
    padding: 1rem 0; }

header.home {
    background: url('../images/slider-01.jpg') no-repeat center !important;
    background-size: auto;
    background-size: cover !important; }

.hero {
    color: #fff; }


/***** NAV *****/

.home .navbar-collapse.collapse.show, .home .nav-bg-toggler {
    background-color: #fff; }

.custom-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(50, 167, 125, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E"); }

.custom-toggler .navbar-toggler {
  border-color: $am-green; }

.nav-item {
    border-bottom: 3px solid transparent;
    transition: 0.3s;

    &:hover {
        border-bottom: 3px solid $am-green;
        transform: translateY(-4px); }

    a {
        color: #fff;
        font-weight: 700;

        &:hover {
            color: $am-green; } } }

.show a {
    color: $am-green; }

.subpage {
    background-color: #343a40 !important; }

/***** HERO *****/

.hero {
    p {
        padding: 50px 20px 0 20px;
        text-align: center;
        font-size: 1.2rem;

        @media (min-width: 1000px) {
            font-size: 1.6rem; } }

    h1 {
        text-align: center;
        padding: 0 20px 120px 20px;
        font-size: 3rem;

        @media (min-width: 1000px) {
            padding: 0 80px 120px 80px;
            font-size: 4rem; } }

    .btn-primary {
        height: 45px;
        width: 100%; }

    form {
        margin-bottom: 20px; } }

.formIcon {
    padding-left: 30px;
    border-radius: 4px !important;
    height: 45px;
    margin-bottom: 10px; }

.formIcon-car {
    background: #fff url('../images/car.png') no-repeat left; }

.formIcon-start {
    background: #fff url('../images/location.png') no-repeat left; }

.formIcon-end {
    background: #fff url('../images/location-1.png') no-repeat left; }

/***** Footer *****/

footer {
    color: #fff;
    /*margin-top: 210px*/
    background: url('../images/footer.jpg') no-repeat bottom center;
    background-size: cover;

    &.wfnl {
        margin-top: 210px; }

    a {
        color: #fff;

        &:hover {
            color: #ffffff;
            text-decoration: none; } }

    .fnl {
        padding-bottom: 0; }

    h4 {
        font-size: 1.2rem;
        padding-bottom: 15px; }

    .btn-primary {
        height: 45px;
        width: 100%; }

    .formIcon {
        padding-left: 35px; }

    .copyright {
        border-top: 1px solid $am-green;
        margin-top: 20px;
        padding-top: 10px;

        img {
            width: 200px; }

        ul {
            margin: 0;
            padding: 0;

            li {
                list-style: none;
                margin: 0;
                padding: 0 0 5px 0;
                transition: 0.3s;
                display: inline; } }

        .fa-inverse {
            color: #000; } }

    ul.footer-list {
        margin: 0;
        padding: 0;

        li {
            list-style: none;
            margin: 0;
            padding: 0 0 5px 0;
            transition: 0.3s;

            &:hover {
                transform: translateY(-4px); } } } }

.formIcon-user {
    background: #fff url('../images/name-icon.png') no-repeat left; }

.formIcon-mail {
    background: #fff url('../images/mail-icon.png') no-repeat left; }

.newsletter {
    background: url('../images/footer-top-bg.jpg') repeat-y;
    background-size: cover;
    padding: 50px 30px;
    border: 4px solid $am-green;
    margin-top: -210px; }

/***** Flipcards *****/

.flipcards {
    p {
        padding-bottom: 25px; }

    .flip-img {
        text-align: center; }

    .card {
        height: 405px;
        background: transparent;
        box-shadow: none;
        border: none;
        font-size: 0.9rem; }

    .flipcard {
        margin-bottom: 30px;
        background-color: transparent;
        perspective: 1000px;

        &:hover .card-body {
            transform: rotateY(180deg); }

        @media (min-width: 576px) {
            margin-bottom: 0;

            &:nth-of-type(even) {
                margin-top: 50px; } } }

    .card-body {
        position: relative;
        transition: transform 0.36s;
        transform-style: preserve-3d;
        padding: 0; }

    .flipcard-front:hover {
        visibility: hidden; }

    .flipcard-front, .flipcard-back {
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        position: absolute;
        margin-right: 15px;
        width: 100%;
        height: 100%;
        padding: 1.25rem;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); }

    .flipcard-front {
        background-color: #fff; }

    .flipcard-back {
        -webkit-transform: rotateY(180deg);
        transform: rotateY(180deg);
        color: #fff !important;
        background-color: #32a67f; } }


/***** SUBPAGES *****/
.white-box {
    background-color: #fff;
    padding: 30px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); }

/***** WOLANIN *****/

// select

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    // background: url("http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png") white no-repeat 98.5% !important
    // background: url("http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png") white no-repeat calc(100% - 10px) !important
    // content: "\f077"
    // font-family: 'Font Awesome 5 Free', serif
    // font-weight: 900
    // font-style: normal
    // font-size: 1.2rem
    // line-height: 30px
 }    // color: #fff
select::-ms-expand {
    display: none; }

// alert box dismissing
.alert.message-box {
    padding: 0;
    margin: 50px;
    border: none;
    border-radius: 0;
    .white-box {
        position: relative;
        button.close {
            position: absolute;
            top: 15px;
            right: 15px; } } }

// confirmbox (logout / delete)
.confirmbox {
    background: rgba(0,0,0, 0.8);
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    display: none;
    &_content {
        background: #000000;
        border: 4px solid #32a77d;
        color: #ffffff; } }

.hidden {
    display: none; }
