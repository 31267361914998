/* poppins-regular - latin */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/poppins-v12-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Poppins Regular'), local('Poppins-Regular'),
         url('../fonts/poppins-v12-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/poppins-v12-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/poppins-v12-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../fonts/poppins-v12-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/poppins-v12-latin-regular.svg#Poppins') format('svg'); /* Legacy iOS */
  }
  
  /* poppins-italic - latin */
  @font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    src: url('../fonts/poppins-v12-latin-italic.eot'); /* IE9 Compat Modes */
    src: local('Poppins Italic'), local('Poppins-Italic'),
         url('../fonts/poppins-v12-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/poppins-v12-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/poppins-v12-latin-italic.woff') format('woff'), /* Modern Browsers */
         url('../fonts/poppins-v12-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/poppins-v12-latin-italic.svg#Poppins') format('svg'); /* Legacy iOS */
  }
  
  /* poppins-700 - latin */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/poppins-v12-latin-700.eot'); /* IE9 Compat Modes */
    src: local('Poppins Bold'), local('Poppins-Bold'),
         url('../fonts/poppins-v12-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/poppins-v12-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/poppins-v12-latin-700.woff') format('woff'), /* Modern Browsers */
         url('../fonts/poppins-v12-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/poppins-v12-latin-700.svg#Poppins') format('svg'); /* Legacy iOS */
  }
  
  /* poppins-700italic - latin */
  @font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 700;
    src: url('../fonts/poppins-v12-latin-700italic.eot'); /* IE9 Compat Modes */
    src: local('Poppins Bold Italic'), local('Poppins-BoldItalic'),
         url('../fonts/poppins-v12-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/poppins-v12-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/poppins-v12-latin-700italic.woff') format('woff'), /* Modern Browsers */
         url('../fonts/poppins-v12-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/poppins-v12-latin-700italic.svg#Poppins') format('svg'); /* Legacy iOS */
  }
  